var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "schedule-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.loading
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Группы", to: "/timetables" },
                        {
                          text: _vm.timetable.description,
                          to: "/timetable/" + _vm.timetableId
                        },
                        { text: _vm.title }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10", lg: "10" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "success",
                        icon: "mdi-badge-account",
                        title: _vm.title
                      }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _vm.schedule.status === 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _vm.schedule.status === 0
                                        ? _c(
                                            "base-material-alert",
                                            {
                                              attrs: {
                                                color: "warning",
                                                dark: "",
                                                icon: "mdi-zip-disk"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Расписание в архиве!\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "3", md: "1" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "pn"
                                              ),
                                              label: "ПН",
                                              "false-value": 0,
                                              "true-value": 1
                                            },
                                            model: {
                                              value: _vm.schedule.pn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "pn",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.pn"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "facade",
                                                rawName: "v-facade",
                                                value: "##:##",
                                                expression: "'##:##'"
                                              }
                                            ],
                                            staticClass: "mt-n5",
                                            attrs: {
                                              label: "Время",
                                              "error-messages": _vm.fieldError(
                                                "time_pn"
                                              ),
                                              disabled: !_vm.schedule.pn
                                            },
                                            model: {
                                              value: _vm.schedule.time_pn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "time_pn",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.time_pn"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "3", md: "1" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "vt"
                                              ),
                                              label: "ВТ",
                                              "false-value": 0,
                                              "true-value": 1
                                            },
                                            model: {
                                              value: _vm.schedule.vt,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "vt",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.vt"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "facade",
                                                rawName: "v-facade",
                                                value: "##:##",
                                                expression: "'##:##'"
                                              }
                                            ],
                                            staticClass: "mt-n5",
                                            attrs: {
                                              label: "Время",
                                              "error-messages": _vm.fieldError(
                                                "time_vt"
                                              ),
                                              disabled: !_vm.schedule.vt
                                            },
                                            model: {
                                              value: _vm.schedule.time_vt,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "time_vt",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.time_vt"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "3", md: "1" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "sr"
                                              ),
                                              label: "СР",
                                              "false-value": 0,
                                              "true-value": 1
                                            },
                                            model: {
                                              value: _vm.schedule.sr,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "sr",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.sr"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "facade",
                                                rawName: "v-facade",
                                                value: "##:##",
                                                expression: "'##:##'"
                                              }
                                            ],
                                            staticClass: "mt-n5",
                                            attrs: {
                                              label: "Время",
                                              "error-messages": _vm.fieldError(
                                                "time_sr"
                                              ),
                                              disabled: !_vm.schedule.sr
                                            },
                                            model: {
                                              value: _vm.schedule.time_sr,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "time_sr",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.time_sr"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "3", md: "1" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "ch"
                                              ),
                                              label: "ЧТ",
                                              "false-value": 0,
                                              "true-value": 1
                                            },
                                            model: {
                                              value: _vm.schedule.ch,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "ch",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.ch"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "facade",
                                                rawName: "v-facade",
                                                value: "##:##",
                                                expression: "'##:##'"
                                              }
                                            ],
                                            staticClass: "mt-n5",
                                            attrs: {
                                              label: "Время",
                                              "error-messages": _vm.fieldError(
                                                "time_ch"
                                              ),
                                              disabled: !_vm.schedule.ch
                                            },
                                            model: {
                                              value: _vm.schedule.time_ch,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "time_ch",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.time_ch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "3", md: "1" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "pt"
                                              ),
                                              label: "ПТ",
                                              "false-value": 0,
                                              "true-value": 1
                                            },
                                            model: {
                                              value: _vm.schedule.pt,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "pt",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.pt"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "facade",
                                                rawName: "v-facade",
                                                value: "##:##",
                                                expression: "'##:##'"
                                              }
                                            ],
                                            staticClass: "mt-n5",
                                            attrs: {
                                              label: "Время",
                                              "error-messages": _vm.fieldError(
                                                "time_pt"
                                              ),
                                              disabled: !_vm.schedule.pt
                                            },
                                            model: {
                                              value: _vm.schedule.time_pt,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "time_pt",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.time_pt"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "3", md: "1" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "sb"
                                              ),
                                              label: "СБ",
                                              "false-value": 0,
                                              "true-value": 1
                                            },
                                            model: {
                                              value: _vm.schedule.sb,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "sb",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.sb"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "facade",
                                                rawName: "v-facade",
                                                value: "##:##",
                                                expression: "'##:##'"
                                              }
                                            ],
                                            staticClass: "mt-n5",
                                            attrs: {
                                              label: "Время",
                                              "error-messages": _vm.fieldError(
                                                "time_sb"
                                              ),
                                              disabled: !_vm.schedule.sb
                                            },
                                            model: {
                                              value: _vm.schedule.time_sb,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "time_sb",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.time_sb"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "3", md: "1" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "vs"
                                              ),
                                              label: "ВС",
                                              "false-value": 0,
                                              "true-value": 1
                                            },
                                            model: {
                                              value: _vm.schedule.vs,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "vs",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.vs"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "facade",
                                                rawName: "v-facade",
                                                value: "##:##",
                                                expression: "'##:##'"
                                              }
                                            ],
                                            staticClass: "mt-n5",
                                            attrs: {
                                              label: "Время",
                                              "error-messages": _vm.fieldError(
                                                "time_vs"
                                              ),
                                              disabled: !_vm.schedule.vs
                                            },
                                            model: {
                                              value: _vm.schedule.time_vs,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "time_vs",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.time_vs"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mb-0 pb-0 mt-0 pt-0 pt-md-11",
                                          attrs: { cols: "6", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Продолжительность, мин *",
                                              "error-messages": _vm.fieldError(
                                                "duration"
                                              )
                                            },
                                            model: {
                                              value: _vm.schedule.duration,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "duration",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.duration"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "mb-0 pb-0 mt-0 pt-0 pt-md-11",
                                          attrs: { cols: "6", md: "2" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Стоимость занятия",
                                              "error-messages": _vm.fieldError(
                                                "tariff"
                                              )
                                            },
                                            model: {
                                              value: _vm.schedule.tariff,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "tariff",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.tariff"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "11", md: "5" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "startDateMenu",
                                              attrs: {
                                                "close-on-content-click": false,
                                                "return-value": _vm.startDate,
                                                transition: "scale-transition",
                                                "min-width": "290px",
                                                "offset-y": ""
                                              },
                                              on: {
                                                "update:returnValue": function(
                                                  $event
                                                ) {
                                                  _vm.startDate = $event
                                                },
                                                "update:return-value": function(
                                                  $event
                                                ) {
                                                  _vm.startDate = $event
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                readonly: "",
                                                                value: _vm.formatDate(
                                                                  _vm.schedule
                                                                    .start_date
                                                                ),
                                                                label: "с",
                                                                "error-messages": _vm.fieldError(
                                                                  "start_date"
                                                                ),
                                                                color:
                                                                  "secondary",
                                                                "prepend-icon":
                                                                  "mdi-calendar-outline",
                                                                messages:
                                                                  "Дата начала занятий"
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3519803920
                                              ),
                                              model: {
                                                value: _vm.startDateMenu,
                                                callback: function($$v) {
                                                  _vm.startDateMenu = $$v
                                                },
                                                expression: "startDateMenu"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-date-picker",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    locale: "ru-ru",
                                                    landscape: "",
                                                    scrollable: "",
                                                    "first-day-of-week": "1"
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      _vm.startDateMenu = false
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.schedule.start_date,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.schedule,
                                                        "start_date",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "schedule.start_date"
                                                  }
                                                },
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        large: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.startDateMenu = false
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Cancel\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "help-pointer pt-5 pb-5 pl-2 pr-5",
                                              attrs: { title: "Очистить дату" },
                                              on: {
                                                click: function($event) {
                                                  _vm.schedule.start_date = null
                                                  _vm.startDate = ""
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    mdi-close\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "11", md: "5" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "endDateMenu",
                                              attrs: {
                                                "close-on-content-click": false,
                                                "return-value": _vm.endDate,
                                                transition: "scale-transition",
                                                "min-width": "290px",
                                                "offset-y": ""
                                              },
                                              on: {
                                                "update:returnValue": function(
                                                  $event
                                                ) {
                                                  _vm.endDate = $event
                                                },
                                                "update:return-value": function(
                                                  $event
                                                ) {
                                                  _vm.endDate = $event
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                readonly: "",
                                                                value: _vm.formatDate(
                                                                  _vm.schedule
                                                                    .end_date
                                                                ),
                                                                label: "по",
                                                                "error-messages": _vm.fieldError(
                                                                  "end_date"
                                                                ),
                                                                color:
                                                                  "secondary",
                                                                "prepend-icon":
                                                                  "mdi-calendar-outline",
                                                                messages:
                                                                  "Дата окончания занятий (включительно)"
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                4192506747
                                              ),
                                              model: {
                                                value: _vm.endDateMenu,
                                                callback: function($$v) {
                                                  _vm.endDateMenu = $$v
                                                },
                                                expression: "endDateMenu"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-date-picker",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    locale: "ru-ru",
                                                    landscape: "",
                                                    scrollable: "",
                                                    "first-day-of-week": "1"
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      _vm.endDateMenu = false
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.schedule.end_date,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.schedule,
                                                        "end_date",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "schedule.end_date"
                                                  }
                                                },
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        large: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.endDateMenu = false
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Отмена\n                      "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "help-pointer pt-5 pb-5 pl-2 pr-5",
                                              attrs: { title: "Очистить дату" },
                                              on: {
                                                click: function($event) {
                                                  _vm.schedule.end_date = null
                                                  _vm.endDate = ""
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    mdi-close\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "3" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Название",
                                              "error-messages": _vm.fieldError(
                                                "name"
                                              )
                                            },
                                            model: {
                                              value: _vm.schedule.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Описание",
                                              "error-messages": _vm.fieldError(
                                                "description"
                                              )
                                            },
                                            model: {
                                              value: _vm.schedule.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.description"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "3" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "d-inline-block",
                                            attrs: {
                                              label: "Цвет (hex)",
                                              "error-messages": _vm.fieldError(
                                                "color"
                                              )
                                            },
                                            model: {
                                              value: _vm.schedule.color,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.color"
                                            }
                                          }),
                                          _vm.schedule.color
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "d-inline-block mr-1",
                                                  attrs: {
                                                    title: "Очистить цвет",
                                                    small: "",
                                                    dark: "",
                                                    rounded: "",
                                                    icon: "",
                                                    color: "secondary"
                                                  },
                                                  on: { click: _vm.resetColor }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-close")
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-menu",
                                            {
                                              staticClass: "d-inline-block",
                                              attrs: { "offset-y": "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "w-100",
                                                              attrs: {
                                                                color: _vm.color
                                                                  ? _vm.color
                                                                  : "grey",
                                                                dark: "",
                                                                fab: "",
                                                                small: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-eyedropper-variant"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                487264907
                                              )
                                            },
                                            [
                                              _c("v-color-picker", {
                                                staticClass: "mx-auto",
                                                attrs: {
                                                  value: _vm.schedule.color,
                                                  mode: "hexa",
                                                  "show-swatches": ""
                                                },
                                                model: {
                                                  value: _vm.color,
                                                  callback: function($$v) {
                                                    _vm.color = $$v
                                                  },
                                                  expression: "color"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "6" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "type"
                                              ),
                                              label: "Язык/тип занятия *",
                                              "item-value": "id",
                                              "item-text": "name",
                                              items: _vm.types
                                            },
                                            model: {
                                              value: _vm.schedule.type,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "type",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.type"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "6" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "course_id"
                                              ),
                                              label: "Курс *",
                                              "item-value": "id",
                                              "item-text": "name",
                                              items: _vm.courses
                                            },
                                            model: {
                                              value: _vm.schedule.course_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "course_id",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.course_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "6" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "room_id"
                                              ),
                                              label: "Помещение по умолчанию",
                                              "item-value": "id",
                                              "item-text": "name",
                                              items: _vm.rooms,
                                              messages:
                                                "Вы можете выбрать помещение по умолчанию, которое будет подставляться при создании записи о занятии. Если помещения могут меняться, оставьте это поле пустым."
                                            },
                                            model: {
                                              value: _vm.schedule.room_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "room_id",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.room_id"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "6" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "teacher_id"
                                              ),
                                              label:
                                                "Преподаватель по умолчанию",
                                              "item-value": "id",
                                              "item-text": "name",
                                              items: _vm.teachers,
                                              messages:
                                                "Вы можете выбрать преподавателя по умолчанию, который будет подставляться при создании записи о занятии. Если преподаватели могут меняться, оставьте это поле пустым."
                                            },
                                            model: {
                                              value: _vm.schedule.teacher_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "teacher_id",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.teacher_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", lg: "3" }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "online"
                                              ),
                                              label: "Онлайн-занятие",
                                              "false-value": 0,
                                              "true-value": 1,
                                              messages:
                                                "Для проведения занятий в онлайн формате"
                                            },
                                            model: {
                                              value: _vm.schedule.online,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.schedule,
                                                  "online",
                                                  $$v
                                                )
                                              },
                                              expression: "schedule.online"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.schedule.online
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "mb-0 pb-0 mt-0 pt-0",
                                              attrs: { cols: "12", lg: "8" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Ссылка-приглашение на конференцию **",
                                                  "error-messages": _vm.fieldError(
                                                    "zoom_url"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.schedule.zoom_url,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.schedule,
                                                      "zoom_url",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "schedule.zoom_url"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "mb-0 pb-0 mt-0 pt-0",
                                              attrs: { cols: "12", lg: "4" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Пароль от конференции",
                                                  "error-messages": _vm.fieldError(
                                                    "zoom_password"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.schedule.zoom_password,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.schedule,
                                                      "zoom_password",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "schedule.zoom_password"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", lg: "12" } },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text--secondary"
                                                },
                                                [
                                                  _c("b", [_vm._v("**")]),
                                                  _vm._v(
                                                    " Ссылка указанная здесь (в расписании) имеет приоритет над ссылкой в группе, если она\n                    заполнена.\n                  "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pl-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "success",
                                            "min-width": "100"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.saveSchedule()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Сохранить\n                "
                                          )
                                        ]
                                      ),
                                      _vm.schedule.status === 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.archive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Переместить в архив\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.schedule.status === 0 &&
                                      _vm.schedule.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.unarchive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Вернуть из архива\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.schedule.status === 0 &&
                                      _vm.schedule.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-1",
                                              attrs: {
                                                color: "error",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.confirmDialog = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Удалить\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить расписание?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Расписание будет удалено без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeSchedule(_vm.schedule.id)
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }