import axios from 'axios'

export default {
  fetchActiveSchedules () {
    return axios.get('timetableschedules?status=1&expand=name').then(response => {
      return response.data
    })
  },

  fetchSchedules () {
    return axios.get('timetableschedules?expand=schedules,schedules.course').then(response => {
      return response.data
    })
  },

  fetchSchedulesByTimetableId (timetableId) {
    return axios.get('timetableschedules?expand=schedules,schedules.course&timetable_id=' + timetableId).then(response => {
      return response.data
    })
  },

  fetchSchedule (id) {
    return axios.get('timetableschedules/' + id).then(response => {
      return response.data
    })
  },

  createSchedule (schedule) {
    return axios.post('timetableschedules', schedule).then(response => {
      return response.data
    })
  },

  updateSchedule (client) {
    return axios.put('timetableschedules/' + client.id, client).then(response => {
      return response.data
    })
  },

  deleteSchedule (id) {
    return axios.delete('timetableschedules/' + id).then(response => {
      return response.data
    })
  },
}
