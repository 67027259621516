<template>
  <v-container
    id="schedule-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'},{text: 'Группы', to: '/timetables'}, {text: timetable.description, to: '/timetable/' + timetableId}, {text: title}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="10"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
          :title="title"
        >
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row
              justify="center"
            >
              <v-col
                v-if="schedule.status === 0"
                cols="12"
                md="12"
              >
                <base-material-alert
                  v-if="schedule.status === 0"
                  color="warning"
                  dark
                  icon="mdi-zip-disk"
                >
                  Расписание в архиве!
                </base-material-alert>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="3"
                    md="1"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="schedule.pn"
                      :error-messages="fieldError('pn')"
                      label="ПН"
                      :false-value="0"
                      :true-value="1"
                    />
                    <v-text-field
                      v-model="schedule.time_pn"
                      v-facade="'##:##'"
                      class="mt-n5"
                      label="Время"
                      :error-messages="fieldError('time_pn')"
                      :disabled="!schedule.pn"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    md="1"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="schedule.vt"
                      :error-messages="fieldError('vt')"
                      label="ВТ"
                      :false-value="0"
                      :true-value="1"
                    />
                    <v-text-field
                      v-model="schedule.time_vt"
                      v-facade="'##:##'"
                      class="mt-n5"
                      label="Время"
                      :error-messages="fieldError('time_vt')"
                      :disabled="!schedule.vt"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    md="1"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="schedule.sr"
                      :error-messages="fieldError('sr')"
                      label="СР"
                      :false-value="0"
                      :true-value="1"
                    />
                    <v-text-field
                      v-model="schedule.time_sr"
                      v-facade="'##:##'"
                      class="mt-n5"
                      label="Время"
                      :error-messages="fieldError('time_sr')"
                      :disabled="!schedule.sr"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    md="1"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="schedule.ch"
                      :error-messages="fieldError('ch')"
                      label="ЧТ"
                      :false-value="0"
                      :true-value="1"
                    />
                    <v-text-field
                      v-model="schedule.time_ch"
                      v-facade="'##:##'"
                      class="mt-n5"
                      label="Время"
                      :error-messages="fieldError('time_ch')"
                      :disabled="!schedule.ch"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    md="1"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="schedule.pt"
                      :error-messages="fieldError('pt')"
                      label="ПТ"
                      :false-value="0"
                      :true-value="1"
                    />
                    <v-text-field
                      v-model="schedule.time_pt"
                      v-facade="'##:##'"
                      class="mt-n5"
                      label="Время"
                      :error-messages="fieldError('time_pt')"
                      :disabled="!schedule.pt"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    md="1"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="schedule.sb"
                      :error-messages="fieldError('sb')"
                      label="СБ"
                      :false-value="0"
                      :true-value="1"
                    />
                    <v-text-field
                      v-model="schedule.time_sb"
                      v-facade="'##:##'"
                      class="mt-n5"
                      label="Время"
                      :error-messages="fieldError('time_sb')"
                      :disabled="!schedule.sb"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    md="1"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="schedule.vs"
                      :error-messages="fieldError('vs')"
                      label="ВС"
                      :false-value="0"
                      :true-value="1"
                    />
                    <v-text-field
                      v-model="schedule.time_vs"
                      v-facade="'##:##'"
                      class="mt-n5"
                      label="Время"
                      :error-messages="fieldError('time_vs')"
                      :disabled="!schedule.vs"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0 pt-md-11"
                  >
                    <v-text-field
                      v-model="schedule.duration"
                      label="Продолжительность, мин *"
                      :error-messages="fieldError('duration')"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md="2"
                    class="mb-0 pb-0 mt-0 pt-0 pt-md-11"
                  >
                    <v-text-field
                      v-model="schedule.tariff"
                      label="Стоимость занятия"
                      :error-messages="fieldError('tariff')"
                    />
                  </v-col>
                  <v-col
                    cols="11"
                    md="5"
                  >
                    <v-menu
                      ref="startDateMenu"
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="startDate"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(schedule.start_date)"
                          label="с"
                          :error-messages="fieldError('start_date')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          messages="Дата начала занятий"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="schedule.start_date"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        scrollable
                        first-day-of-week="1"
                        @input="startDateMenu = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="startDateMenu = false"
                        >
                          Cancel
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="1"
                  >
                    <v-icon
                      title="Очистить дату"
                      class="help-pointer pt-5 pb-5 pl-2 pr-5"
                      @click="schedule.start_date = null; startDate = ''"
                    >
                      mdi-close
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="11"
                    md="5"
                  >
                    <v-menu
                      ref="endDateMenu"
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="endDate"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          readonly
                          :value="formatDate(schedule.end_date)"
                          label="по"
                          :error-messages="fieldError('end_date')"
                          color="secondary"
                          prepend-icon="mdi-calendar-outline"
                          messages="Дата окончания занятий (включительно)"
                          v-on="on"
                        />
                      </template>

                      <v-date-picker
                        v-model="schedule.end_date"
                        color="secondary"
                        locale="ru-ru"
                        landscape
                        scrollable
                        first-day-of-week="1"
                        @input="endDateMenu = false"
                      >
                        <v-spacer />

                        <v-btn
                          color="secondary"
                          large
                          @click="endDateMenu = false"
                        >
                          Отмена
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="1"
                  >
                    <v-icon
                      title="Очистить дату"
                      class="help-pointer pt-5 pb-5 pl-2 pr-5"
                      @click="schedule.end_date = null; endDate = ''"
                    >
                      mdi-close
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="schedule.name"
                      label="Название"
                      :error-messages="fieldError('name')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="schedule.description"
                      label="Описание"
                      :error-messages="fieldError('description')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="schedule.color"
                      class="d-inline-block"
                      label="Цвет (hex)"
                      :error-messages="fieldError('color')"
                    />
                    <v-btn
                      v-if="schedule.color"
                      title="Очистить цвет"
                      class="d-inline-block mr-1"
                      small
                      dark
                      rounded
                      icon
                      color="secondary"
                      @click="resetColor"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-menu
                      offset-y
                      class="d-inline-block"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :color="color ? color : 'grey'"
                          class="w-100"
                          dark
                          fab
                          small
                          v-on="on"
                        >
                          <v-icon>mdi-eyedropper-variant</v-icon>
                        </v-btn>
                      </template>
                      <v-color-picker
                        v-model="color"
                        :value="schedule.color"
                        mode="hexa"
                        show-swatches
                        class="mx-auto"
                      />
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-select
                      v-model="schedule.type"
                      :error-messages="fieldError('type')"
                      label="Язык/тип занятия *"
                      item-value="id"
                      item-text="name"
                      :items="types"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-select
                      v-model="schedule.course_id"
                      :error-messages="fieldError('course_id')"
                      label="Курс *"
                      item-value="id"
                      item-text="name"
                      :items="courses"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-select
                      v-model="schedule.room_id"
                      :error-messages="fieldError('room_id')"
                      label="Помещение по умолчанию"
                      item-value="id"
                      item-text="name"
                      :items="rooms"
                      messages="Вы можете выбрать помещение по умолчанию, которое будет подставляться при создании записи о занятии. Если помещения могут меняться, оставьте это поле пустым."
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-select
                      v-model="schedule.teacher_id"
                      :error-messages="fieldError('teacher_id')"
                      label="Преподаватель по умолчанию"
                      item-value="id"
                      item-text="name"
                      :items="teachers"
                      messages="Вы можете выбрать преподавателя по умолчанию, который будет подставляться при создании записи о занятии. Если преподаватели могут меняться, оставьте это поле пустым."
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="3"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-checkbox
                      v-model="schedule.online"
                      :error-messages="fieldError('online')"
                      label="Онлайн-занятие"
                      :false-value="0"
                      :true-value="1"
                      messages="Для проведения занятий в онлайн формате"
                    />
                  </v-col>
                </v-row>

                <v-row v-if="schedule.online">
                  <v-col
                    cols="12"
                    lg="8"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="schedule.zoom_url"
                      label="Ссылка-приглашение на конференцию **"
                      :error-messages="fieldError('zoom_url')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="schedule.zoom_password"
                      label="Пароль от конференции"
                      :error-messages="fieldError('zoom_password')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="12"
                    class=""
                  >
                    <p class="text--secondary">
                      <b>**</b> Ссылка указанная здесь (в расписании) имеет приоритет над ссылкой в группе, если она
                      заполнена.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-btn
                    color="success"
                    min-width="100"
                    @click="saveSchedule()"
                  >
                    Сохранить
                  </v-btn>

                  <v-btn
                    v-if="schedule.status === 1"
                    color="warning"
                    min-width="100"
                    @click="archive()"
                  >
                    Переместить в архив
                  </v-btn>

                  <v-btn
                    v-if="schedule.status === 0 && schedule.id > 0"
                    color="warning"
                    min-width="100"
                    @click="unarchive()"
                  >
                    Вернуть из архива
                  </v-btn>

                  <v-btn
                    v-if="schedule.status === 0 && schedule.id > 0"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить расписание?
        </v-card-title>

        <v-card-text>
          Расписание будет удалено без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removeSchedule(schedule.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import schedulesApi from '../services/SchedulesApi'
  import coursesApi from '../services/CoursesApi'
  import teachersApi from '../services/TeachersApi'
  import roomsApi from '../services/RoomsApi'
  import timetableApi from '../services/TimetablesApi'

  export default {
    filters: {
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },

    props: ['id', 'timetableId'],
    data () {
      return {
        schedule: {
          id: null,
          name: null,
        },
        timetable: {
          id: null,
          name: null,
        },
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        confirmDialog: false,
        courses: [{ id: null, name: ' - не выбрано - ' }],
        types: [
          { id: null, name: ' - не выбрано - ' },
          { id: 'en', name: 'Английский' },
          { id: 'de', name: 'Немецкий' },
          { id: 'ch', name: 'Китайский' },
          { id: 'fr', name: 'Французский' },
          { id: 'es', name: 'Испанский' },
          { id: 'ru', name: 'Русский' },
        ],
        teachers: [{ id: null, name: ' - не выбрано - ' }],
        rooms: [{ id: null, name: ' - не выбрано - ' }],

        color: '',

        startDateMenu: false,
        endDateMenu: false,
        startDate: '',
        endDate: '',
      }
    },

    computed: {
      title () {
        if (this.schedule.name && this.schedule.course) return 'Расписание ' + this.schedule.name + ' | ' + this.schedule.course.name
        if (this.schedule.name) return 'Расписание ' + this.schedule.name
        if (this.schedule.course) return 'Расписание ' + this.schedule.course.name
        if (this.id === 'create') return 'Добавить расписание'
        return '#' + this.id
      },
    },

    watch: {
      color: function (val) {
        if (this.schedule) {
          this.schedule.color = val || null
        }
      },
    },

    mounted () {
      document.title = this.title + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      coursesApi
        .fetchCourses()
        .then(response => {
          this.courses = this.courses.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
      teachersApi
        .fetchActiveTeachers()
        .then(response => {
          this.teachers = this.teachers.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
      roomsApi
        .fetchRooms()
        .then(response => {
          this.rooms = this.rooms.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
      timetableApi
        .fetchTimetable(this.timetableId)
        .then(response => {
          this.timetable = response
        }).catch(error => {
          this.error = this.pretty(error.response.data)
        })
      this.fetchData()
    },

    methods: {
      fetchData () {
        if (this.id === 'create') {
          this.loading = false
          this.schedule = {
            id: null,
            name: null,
            status: 1,
          }
        } else {
          schedulesApi
            .fetchSchedule(this.id)
            .then(response => {
              this.schedule = response
              this.color = this.schedule.color || ''
              this.loading = false
              document.title = this.title + ' | ' + process.env.VUE_APP_NAME
            }).catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }
      },

      back () {
        this.$router.push('/')
      },

      saveSchedule () {
        if (this.schedule.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          schedulesApi
            .updateSchedule(this.schedule)
            .then(response => {
              this.schedule = response
              this.fetchData()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          this.schedule.timetable_id = this.timetable.id
          schedulesApi
            .createSchedule(this.schedule)
            .then(response => {
              this.schedule = response
              this.$router.push('/schedule/' + this.timetable.id + '/' + this.schedule.id)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },
      removeSchedule () {
        this.confirmDialog = false
        this.loading = true
        schedulesApi
          .deleteSchedule(this.schedule.id)
          .then(response => {
            // this.template = response
            this.loading = false
            this.$router.go(-1)
          }).catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      resetColor () {
        this.schedule.color = null
      },

      archive () {
        this.schedule.status = 0
        this.saveSchedule()
      },
      unarchive () {
        this.schedule.status = 1
        this.saveSchedule()
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },
    },
  }
</script>
